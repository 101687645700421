import React, {useEffect, useState} from "react";
import { EditGuesser } from "@api-platform/admin";
import { TextInput, CheckboxGroupInput, required } from "react-admin";
import { translate } from "../../translateProvider";
import {createAllowedThemesFormatter} from "./AllowedThemesFormatter";
import {createAllowedThemesParser} from "./AllowedThemesParser";
import { loadBookContents } from "../loadBookContents";

export const DivisionEdit = (props) => {
    let [choices, setChoices] = useState([]);
    async function loadChoices() {
        const bookContents = await loadBookContents()
        const newChoices = Object.entries(bookContents.themes)
            .map((value) => {
                return {
                    value: value[0],
                    label: value[1].title
                }
            });
        setChoices(newChoices)
    }

    useEffect(() => {loadChoices()}, []);
    return (
        <EditGuesser {...props}>
            <TextInput disabled source="teacherRegistrationCode" validate={[required()]} label={translate('division.code')} />
            <CheckboxGroupInput
                translateChoice={false}
                optionValue="value"
                optionText="label"
                source="allowedThemes"
                choices={choices}
                label={translate('division.allowedThemes')}
                row={false}
                format={ createAllowedThemesFormatter(choices) }
                parse={ createAllowedThemesParser(choices) }/>
        </EditGuesser>
    );
};
