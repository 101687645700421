import React from "react";
import { ShowGuesser } from "@api-platform/admin";
import { TextField, BooleanField, FunctionField } from "react-admin";
import { translate } from "../../translateProvider";
import { divisionOptionFormatter } from "./DivisionOptionFormatter";

export const TeacherShow = props => (
    <ShowGuesser {...props}>
        <TextField source="email" label={translate('teacher.email')} />
        <TextField source="firstName" label={translate('teacher.firstName')} />
        <TextField source="lastName" label={translate('teacher.lastName')} />
        <FunctionField source="division" label={translate('teacher.division')} render={record => divisionOptionFormatter(record.division)} />
        <BooleanField source="dean" label={translate('teacher.dean')} />
    </ShowGuesser>
);
