import React from "react";
import { Pagination, TextField } from 'react-admin';
import Layout from "./layout/Layout";
import dataProvider from "./dataProvider";
import { getApiUrl } from "./config/getApiUrl";
import {
    ResourceGuesser,
    ListGuesser,
    FieldGuesser,
    HydraAdmin
} from "@api-platform/admin";
import { authProvider } from "./authProvider";
import { i18nProvider, translate } from "./translateProvider";
import { ShowAllowedThemes } from "./crud/divisions/ShowAllowedThemes";
import { DivisionEdit } from './crud/divisions/DivisionEdit';
import { TeacherCreate } from "./crud/teachers/TeacherCreate";
import { TeacherEdit } from "./crud/teachers/TeacherEdit";
import { TeacherShow } from "./crud/teachers/TeacherShow";
import {DivisionShow} from "./crud/divisions/DivisionShow";

const entrypoint = getApiUrl() + '/index.php';

const TeachersList = props => (
    <ListGuesser {...props} exporter={false} bulkActionButtons={false}>
        <FieldGuesser source="divisionId" label={translate('teacher.division')} sortBy='division.id'/>
        <FieldGuesser source="email" label={translate('teacher.email')} sortBy='user.email.email'/>
        <FieldGuesser source="firstName" label={translate('teacher.firstName')} sortBy='firstName'/>
        <FieldGuesser source="lastName" label={translate('teacher.lastName')} sortBy='lastName'/>
        <FieldGuesser source="dean" label={translate('teacher.dean')} sortBy='dean'/>
    </ListGuesser>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />;

const DivisionsList = props => (
    <ListGuesser {...props} pagination={<PostPagination />} hasCreate={false} exporter={false} bulkActionButtons={false}>
        <TextField source="originId" label={translate('division.id')} sortBy='id' />
        <TextField source="teacherRegistrationCode" label={translate('division.code')} />
        <ShowAllowedThemes source="allowedThemes" label={translate('division.allowedThemes')} sortable={false} />
    </ListGuesser>
);

const AdminUsersList = props => (
    <ListGuesser {...props} exporter={false} bulkActionButtons={false}>
        <FieldGuesser source="name" label={translate('adminUsers.name')}/>
        <FieldGuesser source="email" label={translate('adminUsers.email')} sortBy='user.email.email'/>
    </ListGuesser>
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <HydraAdmin
        disableTelemetry
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        layout={ Layout }
        i18nProvider={i18nProvider}
    >
        <ResourceGuesser
            name="teachers"
            create={TeacherCreate}
            edit={TeacherEdit}
            list={TeachersList}
            show={TeacherShow}
            options={{ label: translate('teacher.label') }}
        />
        <ResourceGuesser
            name="divisions"
            edit={DivisionEdit}
            list={DivisionsList}
            show={DivisionShow}
            options={{ label: translate('division.label')
        }}/>
        <ResourceGuesser name="admin_users" list={AdminUsersList}/>
    </HydraAdmin>
);
