/* eslint-disable no-restricted-globals */
const cached = new Map();
const books = [
    [/proeftuin/i, 'Proeftuin', '9789492667144'],
    [/grondstof/i, 'Grondstof', '9789492667205'],
    [/lef/i, 'Lef!', '9789492667618'],
    [/blauwdruk/i, 'Blauwdruk', '9789492667601'],
]

export function getBookEean(frontendUrl) {
    const url = new URL(frontendUrl || location.href)
    for (const book of books) {
        if (book[0].test(url.hostname)) {
            return book[2];
        }
    }
    return '1234567890123';
}

function _getBookTitle(frontendUrl) {
    const url = new URL(frontendUrl || location.href)
    if (/\.localtest\.me$/i.test(url.hostname)) {
        return 'Local test';
    }
    const isAccept = /(\.accept2web\.nl|\.accept\.paqt\.io)$/i.test(url.hostname)
    const suffix = isAccept ? ' accept' : '';
    for (const book of books) {
        if (book[0].test(url.hostname)) {
            return book[1] + suffix;
        }
    }
    return 'Local' + suffix;
}
export function getBookTitle(frontendUrl) {
    const url = new URL(frontendUrl || location.href)
    if (!cached.has(url.hostname)) {
        cached.set(url.hostname, _getBookTitle(frontendUrl))
    }
    return cached.get(url.hostname);
}
