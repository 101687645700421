import React from "react";
import { EditGuesser } from "@api-platform/admin";
import { TextInput, BooleanInput, required } from "react-admin";
import { translate } from "../../translateProvider";
import { divisionOptionParser } from "./DivisionOptionParser";
import { divisionOptionFormatter } from "./DivisionOptionFormatter";

export const TeacherEdit = props => (
    <EditGuesser {...props}>
        <TextInput disabled source="email" required parse={(value) => value || ''} label={translate('teacher.email')} />
        <TextInput source="firstName" validate={[required()]} parse={(value) => value || ''} label={translate('teacher.firstName')} />
        <TextInput source="lastName" validate={[required()]} parse={(value) => value || ''} label={translate('teacher.lastName')} />
        <TextInput source="division" validate={[required()]} label={translate('teacher.division')} parse={ divisionOptionParser } format={ divisionOptionFormatter } />
        <BooleanInput source="dean" label={translate('teacher.dean')} />
    </EditGuesser>
);
