export function createAllowedThemesParser(choices) {
    return function (value) {
        if (value === null || value === undefined) {
            return null
        }
        value = [].slice.call(value, 0);
        for (let choice of choices) {
            if (value.indexOf(choice.value) === -1) {
                return value;
            }
        }
        return null;
    }
}
