import {getApiUrl} from "../config/getApiUrl";
import {getBookEean} from "../config/getBookTitle";

const cache = new Map();

export async function loadBookContents(){
    const url = getApiUrl() + '/storage/import/' + getBookEean() + '/book-contents.json';
    if (!cache.has(url)) {
        async function doFetch() {
            const response = await fetch(url);
            return await response.json();
        }
        cache.set(url, doFetch());
    }
    return await cache.get(url);
}
