import { getApiUrl } from "./config/getApiUrl";
export const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(getApiUrl() + '/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: "include",
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                return authProvider.checkAuth({})
            });
    },
    logout: params => {
        const request = new Request(getApiUrl() + '/logout', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: "include",
        });
        return fetch(request)
            .then(response => {
                localStorage.removeItem('auth')
                return '/login'
            })
    },
    checkAuth: (params) => {
        const request = new Request(getApiUrl() + '/me', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: "include",
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if (!auth || !auth.user) {
                    throw new Error('Not logged in');
                }
                const identity = {
                    id: auth.user.id,
                    fullName: auth.user.fullName,
                }
                localStorage.setItem('auth', JSON.stringify(identity));
            });
    },
    checkError: error => Promise.resolve(),
    getPermissions: params => {
        const data = JSON.parse(localStorage.getItem('auth'))
        return Promise.resolve(data ? ['ADMIN'] : [])
    },
    getIdentity: () => {
        const data = JSON.parse(localStorage.getItem('auth'))
        return Promise.resolve(data || null)
    },
};
