import { useRecordContext } from 'react-admin';
import {translate} from "../../translateProvider";

export const ShowAllowedThemes = ({ source }) => {
    const record = useRecordContext();
    if (record && record[source]) {
        return (<span>{ translate(record[source].length === 1 ? 'division.allowedThemesOne' : 'division.allowedThemesCount', { count: record[source].length }) }</span>)
    }
    return (<span>{ translate('division.allowedThemesAll')}</span>);
};
