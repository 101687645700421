import { fetchUtils } from 'react-admin';
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra } from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/esm/hydra/parseHydraDocumentation";
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import { getApiUrl } from "./config/getApiUrl";

const apiUrl = getApiUrl();
const entrypoint = apiUrl + '/index.php';
const httpClient = fetchUtils.fetchJson;
const fetchHydra = (url, options = {}) => {
    options.credentials = 'include';
    return baseFetchHydra(url, options)
}

const apiDocumentationParser = (entrypoint) =>
    parseHydraDocumentation(
        entrypoint,
        { credentials: 'include' }
    ).then(
        ({ api }) => ({ api }),
        (result) => {
            if (result.status === 401) {
                return Promise.resolve({
                    api: result.api,
                    customRoutes: [
                        <Route path="/" render={() => {
                            return localStorage.getItem("token") ? window.location.reload() : <Redirect to="/login" />
                        }} />
                    ],
                });
            }

            return Promise.reject(result);
        },
    );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);

const diff = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key]) ? diff(value, base[key]) : value;
      }
    });
  };

// We overwrite the dataProvider.update to force the method to be PATCH
dataProvider.update = (resource, params) => {
    return httpClient(`${apiUrl}${params.id}`, {
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json'
        }),
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify(diff(params.data, params.previousData || {})),
    }).then(({ json }) => ({ data: json }));
};

export default dataProvider;
