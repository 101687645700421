import React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { TextInput, BooleanInput } from "react-admin";
import { translate } from "../../translateProvider";
import { divisionOptionFormatter } from "./DivisionOptionFormatter";
import { divisionOptionParser } from "./DivisionOptionParser";

export const TeacherCreate = props => (
    <CreateGuesser {...props}>
        <TextInput autoCompleteType="email" source="email" required parse={(value) => value || ''} label={translate('teacher.email')} />
        <TextInput source="firstName" required parse={(value) => value || ''} label={translate('teacher.firstName')} />
        <TextInput source="lastName" required parse={(value) => value || ''} label={translate('teacher.lastName')} />
        <TextInput source="division" required label={translate('teacher.division')} parse={divisionOptionParser} format={divisionOptionFormatter} />
        <BooleanInput source="dean" label={translate('teacher.dean')} />
    </CreateGuesser>
);
