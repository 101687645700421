import React from 'react';
import { getBookTitle } from '../config/getBookTitle';

const Logo = (props) => (
  <div>
    LesLab { getBookTitle() }
  </div>
);

export default Logo;
