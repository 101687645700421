/* eslint-disable no-restricted-globals */
const cached = new Map();
function _getApiUrl(frontendUrl) {
    const url = new URL(frontendUrl || location.href)
    if (/^localhost$/i.test(url.hostname) || /\.localtest\.me$/i.test(url.hostname)) {
        return 'https://lesapp-api-admin-proeftuin.paqt.dev';
    }
    if (/lesapp-admin-[a-z-0-9.]+\.paqt\.dev/.test(url.hostname)) {
        return url.protocol + '//' + String(url.hostname).replace(/^lesapp-admin-/, 'lesapp-api-admin-');
    }
    if (/^admin\.[a-z-0-9.]+\.paqt\.io$/i.test(url.hostname)) {
        return url.protocol + '//' + String(url.hostname).replace(/^admin\./i, 'api-admin.');
    }
    if (/^admin\.[a-z-0-9.]+\.nl$/i.test(url.hostname)) {
        return url.protocol + '//' + String(url.hostname).replace(/^admin\./i, 'api-admin.');
    }
    throw new Error('Unknown url ' + url.hostname);
}
export function getApiUrl(frontendUrl) {
    const url = new URL(frontendUrl || location.href)
    if (!cached.has(url.hostname)) {
        cached.set(url.hostname, _getApiUrl(frontendUrl))
    }
    return cached.get(url.hostname);
}



