import React, {useEffect, useState} from "react";
import { ShowGuesser } from "@api-platform/admin";
import {TextField, ArrayField, SimpleList} from "react-admin";
import { translate } from "../../translateProvider";
import {loadBookContents} from "../loadBookContents";

export const DivisionShow = props => {
    let [choices, setChoices] = useState([]);
    async function loadChoices() {
        const bookContents = await loadBookContents()
        const newChoices = Object.entries(bookContents.themes)
            .map((value) => {
                return {
                    value: value[0],
                    label: value[1].title
                }
            });
        setChoices(newChoices)
    }
    function findChoice(value) {
        for (let choice of choices) {
            if (choice.value === value) {
                return choice.label;
            }
        }
        return value;
    }
    useEffect(() => {loadChoices()}, []);
    return (
        <ShowGuesser {...props}>
            <TextField source="originId" label={translate('division.id')} sortBy='id' />
            <TextField source="teacherRegistrationCode" label={translate('division.code')} />
            <ArrayField
                label={translate('division.allowedThemes')}
                source="allowedThemes"
            >
                <SimpleList
                    linkType={false}
                    primaryText={record => findChoice(record)}
                />
            </ArrayField>
        </ShowGuesser>
    );
}
