export function createAllowedThemesFormatter(choices) {
    const allChoices = [...choices.map((choice) => choice.value)]
    return function (value) {
        if (value === null || value === undefined) {
            return allChoices
        }
        value = [].slice.call(value, 0);
        for (let choice of choices) {
            if (value.indexOf(choice.value) === -1) {
                return value;
            }
        }
        return allChoices;
    }
}
