const messages = {
  teacher: {
    label: 'Leraren',
    division: 'Domeinwerkgroep',
    email: 'E-mailadres',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    dean: 'Decaan',
  },
  division: {
    label: 'Domeinwerkgroep',
    id: 'Domeinwerkgroep',
    code: 'Code',
    allowedThemes: "Gekoppelde thema's",
    allowedThemesAll: "Alle thema's",
    allowedThemesOne: "1 thema",
    allowedThemesCount: "%{count} thema's",
  },
  adminUsers: {
    name: 'Naam',
    email: 'E-mailadres',
  },
}

export default messages;
